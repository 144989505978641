import menuItemDefaultImg from "images/sushi/logo-only.png";


/* This function is only there for demo purposes. It populates placeholder cards */
export const getRandomCards = () => {
    const cards = [
        {
            imageSrc:
                "https://images.unsplash.com/photo-1512621776951-a57141f2eefd?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=500&q=80",
            title: "Chicken Chilled",
            content: "Chicken Main Course",
            price: "£5.99",
            rating: "5.0",
            reviews: "87",
            url: '/reservation'
        },
        {
            imageSrc:
                "https://images.unsplash.com/photo-1582254465498-6bc70419b607?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=500&q=80",
            title: "Samsa Beef",
            content: "Fried Mexican Beef",
            price: "£3.99",
            rating: "4.5",
            reviews: "34",
            url: '/reservation'
        },
        {
            imageSrc:
                "https://images.unsplash.com/photo-1565310022184-f23a884f29da?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=500&q=80",
            title: "Carnet Nachos",
            content: "Chilli Crispy Nachos",
            price: "£3.99",
            rating: "3.9",
            reviews: "26",
            url: '/reservation'
        },
        {
            imageSrc:
                "https://images.unsplash.com/photo-1534422298391-e4f8c172dddb?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=500&q=80",
            title: "Guacamole Mex",
            content: "Mexican Chilli",
            price: "£3.99",
            rating: "4.2",
            reviews: "95",
            url: '/reservation'
        },
        {
            imageSrc:
                "https://images.unsplash.com/photo-1550461716-dbf266b2a8a7?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=500&q=80",
            title: "Chillie Cake",
            content: "Deepfried Chicken",
            price: "£2.99",
            rating: "5.0",
            reviews: "61",
            url: '/reservation'
        },
        {
            imageSrc:
                "https://images.unsplash.com/photo-1476224203421-9ac39bcb3327??ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=500&q=80",
            title: "Nelli",
            content: "Hamburger & Fries",
            price: "£7.99",
            rating: "4.9",
            reviews: "89",
            url: '/reservation'
        },
        {
            imageSrc:
                "https://images.unsplash.com/photo-1455619452474-d2be8b1e70cd?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=500&q=80",
            title: "Jalapeno Poppers",
            content: "Crispy Soyabeans",
            price: "£8.99",
            rating: "4.6",
            reviews: "12",
            url: '/reservation'
        },
        {
            imageSrc:
                "https://images.unsplash.com/photo-1473093226795-af9932fe5856?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=500&q=80",
            title: "Cajun Chicken",
            content: "Roasted Chicken & Egg",
            price: "£7.99",
            rating: "4.2",
            reviews: "19",
            url: '/reservation'
        }
    ];

    // Shuffle array
    return cards.sort(() => Math.random() - 0.5);
};

export const menuTakeaway = {
    'Pham Specials and Sashimi': {
        '_default': [],
        'Pham Specials': [
            {
                uid: 's01',
                imageSrc: menuItemDefaultImg,
                title: "Scallops with creamy spicy sauce",
                content: "Scallop half-baked in creamy spicy sauce",
                price: "£14.00",
                rating: "5.0",
                reviews: "87",
                url: '/reservation'
            },
            {
                uid: 's02',
                imageSrc: menuItemDefaultImg,
                title: "Snow Crab with creamy spicy sauce",
                content: "Snow crab half-baked in creamy spicy sauce",
                price: "£15.00",
                rating: "4.8",
                reviews: "32",
                url: '/reservation'
            },
        ],
        'Specials Sashimi' : [
            {
                uid: 's03',
                imageSrc: menuItemDefaultImg,
                title: "Yellow tail with Jalapeno (7 slices)",
                content: "Yellow tail sashimi in Yuzu soy",
                price: "£20.00",
                rating: "4.9",
                reviews: "89",
                url: '/reservation'
            },
            // {
            //     uid: 's04',
            //     imageSrc: menuItemDefaultImg,
            //     title: "White Tuna with Chef Sauce",
            //     content: "",
            //     price: "£14.50",
            //     rating: "4.6",
            //     reviews: "12",
            //     url: '/reservation'
            // },
            {
                uid: 's05',
                imageSrc: menuItemDefaultImg,
                title: "Seared Salmon with Jalapeno (7 slices)",
                content: "Seared salmon sashimi in Yuzu soy",
                price: "£16.00",
                rating: "4.2",
                reviews: "19",
                url: '/reservation'
            },
            {
                uid: 's06',
                imageSrc: menuItemDefaultImg,
                title: "Salmon New Style (7 slices)",
                content: "Seared salmon in sake soy",
                price: "£16.00",
                rating: "5.0",
                reviews: "61",
                url: '/reservation'
            },
            {
                uid: 's07',
                imageSrc: menuItemDefaultImg,
                title: "Tuna Tataki (7 slices)",
                content: "Seared tuna sashimi in ponzu sauce",
                price: "£19.00",
                rating: "4.2",
                reviews: "95",
                url: '/reservation'
            },
            // {
            //     uid: 's08',
            //     imageSrc: menuItemDefaultImg,
            //     title: "Hirame New Style",
            //     content: "",
            //     price: "£14.50",
            //     rating: "4.2",
            //     reviews: "95",
            //     url: '/reservation'
            // }
        ],
        'Sashimi set': [
            {
                uid: 'ss_ss01',
                imageSrc: menuItemDefaultImg,
                title: "Sashimi 9 slice",
                content: "Chef selection of sashimi",
                price: "£25.00",
                rating: "3.9",
                reviews: "26",
                url: '/reservation'
            },
            {
                uid: 'ss_ss02',
                imageSrc: menuItemDefaultImg,
                title: "Sashimi 12 slice",
                content: "Chef selection of sashimi",
                price: "£29.00",
                rating: "3.9",
                reviews: "26",
                url: '/reservation'
            },
            {
                uid: 'ss_ss03',
                imageSrc: menuItemDefaultImg,
                title: "Sashimi 15 slice",
                content: "Chef selection of sashimi",
                price: "£34.00",
                rating: "3.9",
                reviews: "26",
                url: '/reservation'
            },
            {
                uid: 'ss_ss04',
                imageSrc: menuItemDefaultImg,
                title: "Deluxe Sashimi Set (25 Slices)",
                content: "Chef’s selection of Sashimi",
                price: "£62.00",
                rating: "3.9",
                reviews: "26",
                url: '/reservation'
            }
        ], 
        'A la Carte Sashimi': [
            {
                uid: 'ss_alcs01',
                imageSrc: menuItemDefaultImg,
                title: "Salmon Sashimi",
                content: "5 slices",
                price: "£12.00",
                rating: "3.9",
                reviews: "26",
                url: '/reservation'
            },
            {
                uid: 'ss_alcs02',
                imageSrc: menuItemDefaultImg,
                title: "Tuna Sashimi",
                content: "5 slices",
                price: "£14.00",
                rating: "3.9",
                reviews: "26",
                url: '/reservation'
            },
            {
                uid: 'ss_alcs03',
                imageSrc: menuItemDefaultImg,
                title: "Seabream Sashimi",
                content: "",
                price: "£11.00",
                rating: "3.9",
                reviews: "26",
                url: '/reservation'
            },
            {
                uid: 'ss_alcs04',
                imageSrc: menuItemDefaultImg,
                title: "Seabass Sashimi",
                content: "5 slices",
                price: "£12.00",
                rating: "3.9",
                reviews: "26",
                url: '/reservation'
            },
            // {
            //     uid: 'ss_alcs05',
            //     imageSrc: menuItemDefaultImg,
            //     title: "Scallops Sashimi",
            //     content: "5 slices",
            //     price: "£15.20",
            //     rating: "3.9",
            //     reviews: "26",
            //     url: '/reservation'
            // },
            {
                uid: 'ss_alcs06',
                imageSrc: menuItemDefaultImg,
                title: "White Tuna Sashimi",
                content: "",
                price: "£14.00",
                rating: "3.9",
                reviews: "26",
                url: '/reservation'
            },
            {
                uid: 'ss_alcs07',
                imageSrc: menuItemDefaultImg,
                title: "Yellow Tail sashimi",
                content: "5 slices",
                price: "£16.00",
                rating: "3.9",
                reviews: "26",
                url: '/reservation'
            },
            {
                uid: 'ss_alcs08',
                imageSrc: menuItemDefaultImg,
                title: "Toro (Fatty Tuna) sashimi",
                content: "",
                price: "£21.00",
                rating: "3.9",
                reviews: "26",
                url: '/reservation'
            },
            {
                uid: 'ss_alcs09',
                imageSrc: menuItemDefaultImg,
                title: "Turbot sashimi",
                content: "",
                price: "£13.00",
                rating: "3.9",
                reviews: "26",
                url: '/reservation'
            },
            {
                uid: 'ss_alcs10',
                imageSrc: menuItemDefaultImg,
                title: "Eel sashimi",
                content: "",
                price: "£12.00",
                rating: "3.9",
                reviews: "26",
                url: '/reservation'
            },
            {
                uid: 'ss_alcs11',
                imageSrc: menuItemDefaultImg,
                title: "Chu Toro Sashimi",
                content: "5 slices",
                price: "£19.00",
                rating: "3.9",
                reviews: "26",
                url: '/reservation'
            },
            {
                uid: 'ss_alcs12',
                imageSrc: menuItemDefaultImg,
                title: "O Toro Sashimi",
                content: "5 slices",
                price: "£22.00",
                rating: "3.9",
                reviews: "26",
                url: '/reservation'
            }
        ]
    },    
    'Sushi Roll, Sets and Nigiris': {
        "_default": [],
        'Sushi Sets (Chef’s Selection Sushi)': [
            {
                uid: 'ss_sscs01',
                imageSrc: menuItemDefaultImg,
                title: "Tuna and Salmon Sushi Set",
                content: "All Salmon & Tuna",
                price: "£25.00",
                rating: "3.9",
                reviews: "26",
                url: '/reservation'
            },
            {
                uid: 'ss_sscs02',
                imageSrc: menuItemDefaultImg,
                title: "Mixed Sushi set (11 pieces)",
                content: "Chef Selection Roll and Nigiri",
                price: "£23.00",
                rating: "3.9",
                reviews: "26",
                url: '/reservation'
            },
            // {
            //     uid: 'ss_sscs03',
            //     imageSrc: menuItemDefaultImg,
            //     title: "Salmon Sushi Set (11 pieces)",
            //     content: "All Salmon",
            //     price: "£18.00",
            //     rating: "3.9",
            //     reviews: "26",
            //     url: '/reservation'
            // },
            // {
            //     uid: 'ss_sscs04',
            //     imageSrc: menuItemDefaultImg,
            //     title: "Salmon Combo Set (17 pieces)",
            //     content: "Salmon sushi and Sashimi",
            //     price: "£20.00",
            //     rating: "3.9",
            //     reviews: "26",
            //     url: '/reservation'
            // },
            // {
            //     uid: 'ss_sscs05',
            //     imageSrc: menuItemDefaultImg,
            //     title: "House Sushi Set (16 pieces)",
            //     content: "Futomaki Salmon Roll and 10 pcs Nigiri",
            //     price: "£30.00",
            //     rating: "3.9",
            //     reviews: "26",
            //     url: '/reservation'
            // },
            // {
            //     uid: 'ss_sscs06',
            //     imageSrc: menuItemDefaultImg,
            //     title: "Deluxe Sushi Set (16 pieces)",
            //     content: "",
            //     price: "£20.00",
            //     rating: "3.9",
            //     reviews: "26",
            //     url: '/reservation'
            // },
            // {
            //     uid: 'ss_sscs07',
            //     imageSrc: menuItemDefaultImg,
            //     title: "Sushi & Sashimi Combo Set",
            //     content: "Mixture of Sushi and Sashimi",
            //     price: "£18.00",
            //     rating: "3.9",
            //     reviews: "26",
            //     url: '/reservation'
            // },
            {
                uid: 'ss_sscs08',
                imageSrc: menuItemDefaultImg,
                title: "Salmon Chirashi",
                content: "Salmon Sashimi on Sushi Rice",
                price: "£18.00",
                rating: "3.9",
                reviews: "26",
                url: '/reservation'
            },
            {
                uid: 'ss_sscs09',
                imageSrc: menuItemDefaultImg,
                title: "Chirashi",
                content: "Chef's selection of sashimi on sushi rice",
                price: "£19.00",
                rating: "3.9",
                reviews: "26",
                url: '/reservation'
            },
            // {
            //     uid: 'ss_sscs10',
            //     imageSrc: menuItemDefaultImg,
            //     title: "Classic Set",
            //     content: "6 pcs Crunchy Yellow Tail, 5 slice Sashimi & 4 kind Nigiri",
            //     price: "£27.55",
            //     rating: "3.9",
            //     reviews: "26",
            //     url: '/reservation'
            // },
            // {
            //     uid: 'ss_sscs11',
            //     imageSrc: menuItemDefaultImg,
            //     title: "Deluxe Tuna & Salmon Set",
            //     content: "4pcs Tuna & Salmon Nigiri, 6 pcs Salmon & Tuna Sashimi and 8 pcs Crunchy Tuna Roll",
            //     price: "£33.25",
            //     rating: "3.9",
            //     reviews: "26",
            //     url: '/reservation'
            // },
            // {
            //     uid: 'ss_sscs12',
            //     imageSrc: menuItemDefaultImg,
            //     title: "Tempura Set",
            //     content: "6 pcs prawn tempura roll, 6 pcs Crab roll, 3 piece prawn nigiri",
            //     price: "£25.00",
            //     rating: "3.9",
            //     reviews: "26",
            //     url: '/reservation'
            // },
            {
                uid: 'ss_sscs13',
                imageSrc: menuItemDefaultImg,
                title: "Combination Sushi Set",
                content: "",
                price: "£35.00",
                rating: "3.9",
                reviews: "26",
                url: '/reservation'
            },
            {
                uid: 'ss_sscs14',
                imageSrc: menuItemDefaultImg,
                title: "Chef Set",
                content: "",
                price: "£35.00",
                rating: "3.9",
                reviews: "26",
                url: '/reservation'
            },
            {
                uid: 'ss_sscs15',
                imageSrc: menuItemDefaultImg,
                title: "Pham Set",
                content: "",
                price: "£30.00",
                rating: "3.9",
                reviews: "26",
                url: '/reservation'
            },
            {
                uid: 'ss_sscs16',
                imageSrc: menuItemDefaultImg,
                title: "Salmon Don",
                content: "",
                price: "£20.00",
                rating: "3.9",
                reviews: "26",
                url: '/reservation'
            },
            {
                uid: 'ss_sscs17',
                imageSrc: menuItemDefaultImg,
                title: "Chirashi Don",
                content: "",
                price: "£21.00",
                rating: "3.9",
                reviews: "26",
                url: '/reservation'
            },
            {
                uid: 'ss_sscs18',
                imageSrc: menuItemDefaultImg,
                title: "Tuna Don",
                content: "",
                price: "£21.00",
                rating: "3.9",
                reviews: "26",
                url: '/reservation'
            }
        ],
        'A la Carte Nigiri (2 pieces)': [
            {
                uid: 'ss_alcn01',
                imageSrc: menuItemDefaultImg,
                title: "Salmon",
                content: "",
                price: "£6.00",
                rating: "3.9",
                reviews: "26",
                url: '/reservation'
            },
            {
                uid: 'ss_alcn02',
                imageSrc: menuItemDefaultImg,
                title: "Tuna",
                content: "",
                price: "£7.00",
                rating: "3.9",
                reviews: "26",
                url: '/reservation'
            },
            {
                uid: 'ss_alcn03',
                imageSrc: menuItemDefaultImg,
                title: "Toro (Fatty Tuna)",
                content: "",
                price: "£10.50",
                rating: "3.9",
                reviews: "26",
                url: '/reservation'
            },
            {
                uid: 'ss_alcn04',
                imageSrc: menuItemDefaultImg,
                title: "Seabass",
                content: "",
                price: "£6.50",
                rating: "3.9",
                reviews: "26",
                url: '/reservation'
            },
            {
                uid: 'ss_alcn05',
                imageSrc: menuItemDefaultImg,
                title: "Seabream",
                content: "",
                price: "£6.00",
                rating: "3.9",
                reviews: "26",
                url: '/reservation'
            },
            // {
            //     uid: 'ss_alcn06',
            //     imageSrc: menuItemDefaultImg,
            //     title: "Scallops",
            //     content: "",
            //     price: "£7.60",
            //     rating: "3.9",
            //     reviews: "26",
            //     url: '/reservation'
            // },
            // {
            //     uid: 'ss_alcn07',
            //     imageSrc: menuItemDefaultImg,
            //     title: "Mackerel",
            //     content: "",
            //     price: "£5.00",
            //     rating: "3.9",
            //     reviews: "26",
            //     url: '/reservation'
            // },
            {
                uid: 'ss_alcn08',
                imageSrc: menuItemDefaultImg,
                title: "White Tuna (Escolar)",
                content: "",
                price: "£7.00",
                rating: "3.9",
                reviews: "26",
                url: '/reservation'
            },
            {
                uid: 'ss_alcn09',
                imageSrc: menuItemDefaultImg,
                title: "Sweet Shrimp",
                content: "",
                price: "£6.00",
                rating: "3.9",
                reviews: "26",
                url: '/reservation'
            },
            // {
            //     uid: 'ss_alcn10',
            //     imageSrc: menuItemDefaultImg,
            //     title: "Tobiko",
            //     content: "",
            //     price: "£5.5",
            //     rating: "3.9",
            //     reviews: "26",
            //     url: '/reservation'
            // },
            {
                uid: 'ss_alcn11',
                imageSrc: menuItemDefaultImg,
                title: "Prawn",
                content: "",
                price: "£5.00",
                rating: "3.9",
                reviews: "26",
                url: '/reservation'
            },
            {
                uid: 'ss_alcn12',
                imageSrc: menuItemDefaultImg,
                title: "Yellow Tail",
                content: "",
                price: "£8.00",
                rating: "3.9",
                reviews: "26",
                url: '/reservation'
            },
            {
                uid: 'ss_alcn13',
                imageSrc: menuItemDefaultImg,
                title: "Ikura",
                content: "",
                price: "£9.00",
                rating: "3.9",
                reviews: "26",
                url: '/reservation'
            },
            {
                uid: 'ss_alcn14',
                imageSrc: menuItemDefaultImg,
                title: "Eel",
                content: "",
                price: "£6.50",
                rating: "3.9",
                reviews: "26",
                url: '/reservation'
            },
            {
                uid: 'ss_alcn15',
                imageSrc: menuItemDefaultImg,
                title: "Special Salmon Nigiri",
                content: "",
                price: "£7.50",
                rating: "3.9",
                reviews: "26",
                url: '/reservation'
            },
            {
                uid: 'ss_alcn16',
                imageSrc: menuItemDefaultImg,
                title: "Special Tuna Nigiri",
                content: "",
                price: "£8.50",
                rating: "3.9",
                reviews: "26",
                url: '/reservation'
            },
            {
                uid: 'ss_alcn17',
                imageSrc: menuItemDefaultImg,
                title: "Chu Toro",
                content: "",
                price: "£9.50",
                rating: "3.9",
                reviews: "26",
                url: '/reservation'
            },
            {
                uid: 'ss_alcn18',
                imageSrc: menuItemDefaultImg,
                title: "O Toro",
                content: "",
                price: "£11.00",
                rating: "3.9",
                reviews: "26",
                url: '/reservation'
            },
            {
                uid: 'ss_alcn19',
                imageSrc: menuItemDefaultImg,
                title: "Turbot",
                content: "",
                price: "£7.00",
                rating: "3.9",
                reviews: "26",
                url: '/reservation'
            }
        ],
        "Sushi Roll": [
            {
                uid: 'ss_sr01',
                imageSrc: menuItemDefaultImg,
                title: "Yellow Tail Crunchy Roll",
                content: "Yellow Tail, Tempura Flakes and avocado",
                price: "£12.00",
                rating: "3.9",
                reviews: "26",
                url: '/reservation'
            },
            // {
            //     uid: 'ss_sr02',
            //     imageSrc: menuItemDefaultImg,
            //     title: "Eel and Cucumber Roll",
            //     content: "",
            //     price: "£7.00",
            //     rating: "3.9",
            //     reviews: "26",
            //     url: '/reservation'
            // },
            // {
            //     uid: 'ss_sr03',
            //     imageSrc: menuItemDefaultImg,
            //     title: "Scallop Tobiko Roll",
            //     content: "Scallions, fish eggs and Scallops",
            //     price: "£3.99",
            //     rating: "3.9",
            //     reviews: "26",
            //     url: '/reservation'
            // },
            {
                uid: 'ss_sr04',
                imageSrc: menuItemDefaultImg,
                title: "Rainbow Roll",
                content: "White fish, Tuna, Salmon and avocado",
                price: "£15.00",
                rating: "3.9",
                reviews: "26",
                url: '/reservation'
            },
            {
                uid: 'ss_sr05',
                imageSrc: menuItemDefaultImg,
                title: "Crunchy Tuna Roll",
                content: "Salmon, Tempura flakes and seared Tuna",
                price: "£12.00",
                rating: "3.9",
                reviews: "26",
                url: '/reservation'
            },
            // {
            //     uid: 'ss_sr06',
            //     imageSrc: menuItemDefaultImg,
            //     title: "Crunchy Turbot Roll",
            //     content: "Tuna, Prawn with Turbot Sashimi",
            //     price: "£13.50",
            //     rating: "3.9",
            //     reviews: "26",
            //     url: '/reservation'
            // },
            {
                uid: 'ss_sr07',
                imageSrc: menuItemDefaultImg,
                title: "Flying Dragon Roll",
                content: "Eel and Asparagusl with Tempura flakes in Avocado",
                price: "£16.00",
                rating: "3.9",
                reviews: "26",
                url: '/reservation'
            },
            {
                uid: 'ss_sr08',
                imageSrc: menuItemDefaultImg,
                title: "Samurai Roll",
                content: "Spicy Prawn tempura rolls with Eel and jalapeno",
                price: "£15.00",
                rating: "3.9",
                reviews: "26",
                url: '/reservation'
            },
            {
                uid: 'ss_sr09',
                imageSrc: menuItemDefaultImg,
                title: "Pham Roll",
                content: "Snow crab and Avocado roll in white tuna sashimi",
                price: "£14.00",
                rating: "3.9",
                reviews: "26",
                url: '/reservation'
            },
            {
                uid: 'ss_sr10',
                imageSrc: menuItemDefaultImg,
                title: "Salmon Jalapeno Roll",
                content: "Salmon with Jalapeno, tempura flakes",
                price: "£9.00",
                rating: "3.9",
                reviews: "26",
                url: '/reservation'
            },
            {
                uid: 'ss_sr11',
                imageSrc: menuItemDefaultImg,
                title: "Soft Shell Crab Roll",
                content: "Crab with avocado and cucumber",
                price: "£13.50",
                rating: "3.9",
                reviews: "26",
                url: '/reservation'
            },
            {
                uid: 'ss_sr12',
                imageSrc: menuItemDefaultImg,
                title: "King Prawn Tempura Roll",
                content: "Prawn, avocado and Mayonaise",
                price: "£10.00",
                rating: "3.9",
                reviews: "26",
                url: '/reservation'
            },
            {
                uid: 'ss_sr13',
                imageSrc: menuItemDefaultImg,
                title: "California Roll",
                content: "Crabstick with Cucumber",
                price: "£8.00",
                rating: "3.9",
                reviews: "26",
                url: '/reservation'
            },
            {
                uid: 'ss_sr14',
                imageSrc: menuItemDefaultImg,
                title: "Alaska",
                content: "Salmon, avocado and mayonnaise",
                price: "£8.30",
                rating: "3.9",
                reviews: "26",
                url: '/reservation'
            },
            {
                uid: 'ss_sr15',
                imageSrc: menuItemDefaultImg,
                title: "Grilled Salmon Skin Roll",
                content: "With Avocado and scallions",
                price: "£8.00",
                rating: "3.9",
                reviews: "26",
                url: '/reservation'
            },
            {
                uid: 'ss_sr16',
                imageSrc: menuItemDefaultImg,
                title: "Spicy Tuna Roll",
                content: "Tuna with Scallions and Spicy mayo",
                price: "£8.50",
                rating: "3.9",
                reviews: "26",
                url: '/reservation'
            },
            // {
            //     uid: 'ss_sr17',
            //     imageSrc: menuItemDefaultImg,
            //     title: "Yellow Tail Scallion Roll",
            //     content: "Yellow Tail with Spring onion",
            //     price: "£6.70",
            //     rating: "3.9",
            //     reviews: "26",
            //     url: '/reservation'
            // },
            // {
            //     uid: 'ss_sr18',
            //     imageSrc: menuItemDefaultImg,
            //     title: "Chef Special Roll",
            //     content: "Big roll with Salmon, Tuna, White fish",
            //     price: "£9.50",
            //     rating: "3.9",
            //     reviews: "26",
            //     url: '/reservation'
            // },
            // {
            //     uid: 'ss_sr19',
            //     imageSrc: menuItemDefaultImg,
            //     title: "Seared Spicy Tuna Roll",
            //     content: "Seared Tuna, spring onion and Shiracha",
            //     price: "£9.00",
            //     rating: "3.9",
            //     reviews: "26",
            //     url: '/reservation'
            // },
            {
                uid: 'ss_sr20',
                imageSrc: menuItemDefaultImg,
                title: "Crunchy Salmon Roll",
                content: "Salmon, Asparagus, Tempura Flakes",
                price: "£10.50",
                rating: "3.9",
                reviews: "26",
                url: '/reservation'
            },
            {
                uid: 'ss_sr21',
                imageSrc: menuItemDefaultImg,
                title: "Tuna Roll",
                content: "",
                price: "£7.00",
                rating: "3.9",
                reviews: "26",
                url: '/reservation'
            },
            {
                uid: 'ss_sr22',
                imageSrc: menuItemDefaultImg,
                title: "Salmon Roll",
                content: "",
                price: "£6.00",
                rating: "3.9",
                reviews: "26",
                url: '/reservation'
            },
            {
                uid: 'ss_sr23',
                imageSrc: menuItemDefaultImg,
                title: "Spicy salmon with Truffle Roll",
                content: "",
                price: "£8.00",
                rating: "3.9",
                reviews: "26",
                url: '/reservation'
            },
            {
                uid: 'ss_sr24',
                imageSrc: menuItemDefaultImg,
                title: "Yellow tail Jalapeno Roll",
                content: "",
                price: "£11.00",
                rating: "3.9",
                reviews: "26",
                url: '/reservation'
            },
            {
                uid: 'ss_sr25',
                imageSrc: menuItemDefaultImg,
                title: "Toro crunchy Roll",
                content: "",
                price: "£16.00",
                rating: "3.9",
                reviews: "26",
                url: '/reservation'
            },
            {
                uid: 'ss_sr26',
                imageSrc: menuItemDefaultImg,
                title: "Pham Special Roll",
                content: "",
                price: "£14.00",
                rating: "3.9",
                reviews: "26",
                url: '/reservation'
            },
            {
                uid: 'ss_sr26',
                imageSrc: menuItemDefaultImg,
                title: "Negi Toro Roll",
                content: "",
                price: "£10.00",
                rating: "3.9",
                reviews: "26",
                url: '/reservation'
            },
            {
                uid: 'ss_sr27',
                imageSrc: menuItemDefaultImg,
                title: "Salmon Skin Roll",
                content: "",
                price: "£8.00",
                rating: "3.9",
                reviews: "26",
                url: '/reservation'
            },
            {
                uid: 'ss_sr28',
                imageSrc: menuItemDefaultImg,
                title: "Tuna Jalapeno Roll",
                content: "",
                price: "£10.00",
                rating: "3.9",
                reviews: "26",
                url: '/reservation'
            }
        ],
        'Vegan Sushi': [
            {
                uid: 'nvs_vs01',
                imageSrc: menuItemDefaultImg,
                title: "Mixed Vegetable Roll",
                content: "",
                price: "£7.00",
                rating: "3.9",
                reviews: "26",
                url: '/reservation'
            },
            {
                uid: 'nvs_vs02',
                imageSrc: menuItemDefaultImg,
                title: "Cucumber Maki",
                content: "",
                price: "£5.00",
                rating: "3.9",
                reviews: "26",
                url: '/reservation'
            },
            {
                uid: 'nvs_vs03',
                imageSrc: menuItemDefaultImg,
                title: "Avocado Maki",
                content: "",
                price: "£5.50",
                rating: "3.9",
                reviews: "26",
                url: '/reservation'
            },
            {
                uid: 'nvs_vs04',
                imageSrc: menuItemDefaultImg,
                title: "Vegetable Sushi Set",
                content: "",
                price: "£12.00",
                rating: "3.9",
                reviews: "26",
                url: '/reservation'
            },
            {
                uid: 'nvs_vs05',
                imageSrc: menuItemDefaultImg,
                title: "Deluxe Vegan Set",
                content: "",
                price: "£15.00",
                rating: "3.9",
                reviews: "26",
                url: '/reservation'
            },
            {
                uid: 'nvs_vs06',
                imageSrc: menuItemDefaultImg,
                title: "Asparagus Maki",
                content: "",
                price: "£5.50",
                rating: "3.9",
                reviews: "26",
                url: '/reservation'
            },
            {
                uid: 'nvs_vs07',
                imageSrc: menuItemDefaultImg,
                title: "Inari Nigiri",
                content: "",
                price: "£5.00",
                rating: "3.9",
                reviews: "26",
                url: '/reservation'
            }
        ]
    },
    'Tempura, Appetizers & Grilled Dish': { 
        '_default': [], 
        'Tempura': [
            {
                uid: 'ta_t01',
                imageSrc: menuItemDefaultImg,
                title: "Tempura Moriawase",
                content: "Mixture of Fish, Prawn & Vegetables",
                price: "£16.00",
                rating: "3.9",
                reviews: "26",
                url: '/reservation'
            },
            {
                uid: 'ta_t02',
                imageSrc: menuItemDefaultImg,
                title: "Ebi Tempura",
                content: "5 piece of Prawn Tempura",
                price: "£16.00",
                rating: "3.9",
                reviews: "26",
                url: '/reservation'
            },
            {
                uid: 'ta_t03',
                imageSrc: menuItemDefaultImg,
                title: "Vegetable Tempura",
                content: "",
                price: "£11.00",
                rating: "3.9",
                reviews: "26",
                url: '/reservation'
            },
            {
                uid: 'ta_t04',
                imageSrc: menuItemDefaultImg,
                title: "Rock Shrimp Tempura in Spicy sauce",
                content: "Shrimp in Spicy Mayo",
                price: "£15.00",
                rating: "3.9",
                reviews: "26",
                url: '/reservation'
            },
            {
                uid: 'ta_t05',
                imageSrc: menuItemDefaultImg,
                title: "Softshell Crab Tempura",
                content: "Whole Crab with vegetables",
                price: "£13.50",
                rating: "3.9",
                reviews: "26",
                url: '/reservation'
            }
        ], 
        'Appetizers': [
            {
                uid: 'ta_a01',
                imageSrc: menuItemDefaultImg,
                title: "Edamame (Salt or Spicy) V",
                content: "",
                price: "£5.00",
                rating: "3.9",
                reviews: "26",
                url: '/reservation'
            },
            {
                uid: 'ta_a02',
                imageSrc: menuItemDefaultImg,
                title: "Edamame Spring Roll V",
                content: "",
                price: "£6.00",
                rating: "3.9",
                reviews: "26",
                url: '/reservation'
            },
            {
                uid: 'ta_a03',
                imageSrc: menuItemDefaultImg,
                title: "Chicken Yakitori",
                content: "Grilled Chicken in Yakitori sauce",
                price: "£7.50",
                rating: "3.9",
                reviews: "26",
                url: '/reservation'
            },
            {
                uid: 'ta_a04',
                imageSrc: menuItemDefaultImg,
                title: "Horenso No Goma-ae V",
                content: "Boiled spinach with sesame dressing",
                price: "£5.00",
                rating: "3.9",
                reviews: "26",
                url: '/reservation'
            },
            // {
            //     uid: 'ta_a05',
            //     imageSrc: menuItemDefaultImg,
            //     title: "Beef Skewers (2 pieces)",
            //     content: "",
            //     price: "£7.00",
            //     rating: "3.9",
            //     reviews: "26",
            //     url: '/reservation'
            // },
            {
                uid: 'ta_a06',
                imageSrc: menuItemDefaultImg,
                title: "Nasu Dengaku V",
                content: "Grilled Aubergine with Sweet miso",
                price: "£8.00",
                rating: "3.9",
                reviews: "26",
                url: '/reservation'
            },
            {
                uid: 'ta_a07',
                imageSrc: menuItemDefaultImg,
                title: "Age dashi Tofu",
                content: "Deep fried Bean curd",
                price: "£7.50",
                rating: "3.9",
                reviews: "26",
                url: '/reservation'
            },
            {
                uid: 'ta_a08',
                imageSrc: menuItemDefaultImg,
                title: "Japanese Spring Roll",
                content: "Prawn & Mushroom",
                price: "£7.00",
                rating: "3.9",
                reviews: "26",
                url: '/reservation'
            },
            {
                uid: 'ta_a09',
                imageSrc: menuItemDefaultImg,
                title: "House Spring Roll",
                content: "Mince Pork & Vegetable",
                price: "£7.00",
                rating: "3.9",
                reviews: "26",
                url: '/reservation'
            },
            {
                uid: 'ta_a10',
                imageSrc: menuItemDefaultImg,
                title: "Pork Gyoza",
                content: "(4 pieces)",
                price: "£8.00",
                rating: "3.9",
                reviews: "26",
                url: '/reservation'
            },
            {
                uid: 'ta_a11',
                imageSrc: menuItemDefaultImg,
                title: "Chicken Gyoza",
                content: "(4 pieces)",
                price: "£8.00",
                rating: "3.9",
                reviews: "26",
                url: '/reservation'
            },
            {
                uid: 'ta_a12',
                imageSrc: menuItemDefaultImg,
                title: "Deep Fried Squid Tentacles",
                content: "Served with Ponzu",
                price: "£8.00",
                rating: "3.9",
                reviews: "26",
                url: '/reservation'
            },
            {
                uid: 'ta_a13',
                imageSrc: menuItemDefaultImg,
                title: "Chicken Karaage",
                content: "Deep Fried Chicken",
                price: "£7.60",
                rating: "3.9",
                reviews: "26",
                url: '/reservation'
            },
            // {
            //     uid: 'ta_a14',
            //     imageSrc: menuItemDefaultImg,
            //     title: "Chicken Katsu",
            //     content: "Chicken in breadcrumbs",
            //     price: "£8.00",
            //     rating: "3.9",
            //     reviews: "26",
            //     url: '/reservation'
            // },
            {
                uid: 'ta_a15',
                imageSrc: menuItemDefaultImg,
                title: "Prawn Crunchy Bites (Prawn with pastry skin)",
                content: "",
                price: "£7.50",
                rating: "3.9",
                reviews: "26",
                url: '/reservation'
            },
            {
                uid: 'ta_a16',
                imageSrc: menuItemDefaultImg,
                title: "Prawn Crunchy Bites (Prawn with pastry skin)",
                content: "",
                price: "£7.50",
                rating: "3.9",
                reviews: "26",
                url: '/reservation'
            },
            {
                uid: 'ta_a17',
                imageSrc: menuItemDefaultImg,
                title: "Octopus Ball (Takoyaki)",
                content: "",
                price: "£7.00",
                rating: "3.9",
                reviews: "26",
                url: '/reservation'
            },
            {
                uid: 'ta_a18',
                imageSrc: menuItemDefaultImg,
                title: "Chicken Ball (Mince Chicken with yakitori sauce)",
                content: "",
                price: "£7.00",
                rating: "3.9",
                reviews: "26",
                url: '/reservation'
            }
        ],
        'Grilled Dishes': [
            {
                uid: 'ta_gd01',
                imageSrc: menuItemDefaultImg,
                title: "Black Cod Miso",
                content: "Black Cod marinated in Sweet Miso",
                price: "£29.00",
                rating: "3.9",
                reviews: "26",
                url: '/reservation'
            },
            // {
            //     uid: 'ta_gd02',
            //     imageSrc: menuItemDefaultImg,
            //     title: "Grilled Beef steak",
            //     content: "Choice of Teriyaki or Wafu sauce",
            //     price: "£16",
            //     rating: "3.9",
            //     reviews: "26",
            //     url: '/reservation'
            // },
            // {
            //     uid: 'ta_gd03',
            //     imageSrc: menuItemDefaultImg,
            //     title: "Grilled Salmon Steak in Teriyaki",
            //     content: "Choice of Teriyaki or Wafu sauce",
            //     price: "£10.00",
            //     rating: "3.9",
            //     reviews: "26",
            //     url: '/reservation'
            // },
            // {
            //     uid: 'ta_gd04',
            //     imageSrc: menuItemDefaultImg,
            //     title: "Grilled Chicken in Teriyaki sauce",
            //     content: "",
            //     price: "£9.00",
            //     rating: "3.9",
            //     reviews: "26",
            //     url: '/reservation'
            // },
            {
                uid: 'ta_gd05',
                imageSrc: menuItemDefaultImg,
                title: "Unagi Don",
                content: "",
                price: "£18.50",
                rating: "3.9",
                reviews: "26",
                url: '/reservation'
            },
            {
                uid: 'ta_gd06',
                imageSrc: menuItemDefaultImg,
                title: "Grilled Salmon with Miso",
                content: "",
                price: "£18.00",
                rating: "3.9",
                reviews: "26",
                url: '/reservation'
            },
            {
                uid: 'ta_gd07',
                imageSrc: menuItemDefaultImg,
                title: "Grilled Saba Yaki",
                content: "",
                price: "£13.00",
                rating: "3.9",
                reviews: "26",
                url: '/reservation'
            }
        ],
        'Salads': [
            {
                uid: 'ta_sal01',
                imageSrc: menuItemDefaultImg,
                title: "Seaweed Salad",
                content: "",
                price: "£9.00",
                rating: "3.9",
                reviews: "26",
                url: '/reservation'
            },
            // {
            //     uid: 'ta_sal02',
            //     imageSrc: menuItemDefaultImg,
            //     title: "Avocado and Mix leaf Salad",
            //     content: "",
            //     price: "£8.50",
            //     rating: "3.9",
            //     reviews: "26",
            //     url: '/reservation'
            // },
            // {
            //     uid: 'ta_sal03',
            //     imageSrc: menuItemDefaultImg,
            //     title: "Mix Leaf Salad",
            //     content: "",
            //     price: "£6.00",
            //     rating: "3.9",
            //     reviews: "26",
            //     url: '/reservation'
            // },
            // {
            //     uid: 'ta_sal04',
            //     imageSrc: menuItemDefaultImg,
            //     title: "Mixed Sashimi Salad",
            //     content: "",
            //     price: "£13.00",
            //     rating: "3.9",
            //     reviews: "26",
            //     url: '/reservation'
            // },
            {
                uid: 'ta_sal05',
                imageSrc: menuItemDefaultImg,
                title: "Chuka Salad",
                content: "",
                price: "£7.00",
                rating: "3.9",
                reviews: "26",
                url: '/reservation'
            },
            {
                uid: 'ta_sal06',
                imageSrc: menuItemDefaultImg,
                title: "Avocado Crab Stick Salad",
                content: "",
                price: "£8.50",
                rating: "3.9",
                reviews: "26",
                url: '/reservation'
            },
            {
                uid: 'ta_sal07',
                imageSrc: menuItemDefaultImg,
                title: "Mixed Pickles",
                content: "",
                price: "£5.00",
                rating: "3.9",
                reviews: "26",
                url: '/reservation'
            },
            {
                uid: 'ta_sal08',
                imageSrc: menuItemDefaultImg,
                title: "Salmon Salad (Seared)",
                content: "",
                price: "£14.50",
                rating: "3.9",
                reviews: "26",
                url: '/reservation'
            },
            {
                uid: 'ta_sal09',
                imageSrc: menuItemDefaultImg,
                title: "Tuna Salad (Seared)",
                content: "",
                price: "£17.00",
                rating: "3.9",
                reviews: "26",
                url: '/reservation'
            },
            {
                uid: 'ta_sal10',
                imageSrc: menuItemDefaultImg,
                title: "Mixed Leaf Salad with Avocado & Crabsticks",
                content: "",
                price: "£10.00",
                rating: "3.9",
                reviews: "26",
                url: '/reservation'
            }
        ] 
    },
    'Donburi, Bento’s & Platters': { 
        '_default': [], 
        'Donburi': [
            // {
            //     uid: 'db_d01',
            //     imageSrc: menuItemDefaultImg,
            //     title: "Chirashi",
            //     content: "Chef Selection of Sashimi on Sushi Rice",
            //     price: "£15",
            //     rating: "3.9",
            //     reviews: "26",
            //     url: '/reservation'
            // },
            {
                uid: 'db_d02',
                imageSrc: menuItemDefaultImg,
                title: "Unagi Don",
                content: "Grilled eel on rice and pickles",
                price: "£20.00",
                rating: "3.9",
                reviews: "26",
                url: '/reservation'
            },
            {
                uid: 'db_d03',
                imageSrc: menuItemDefaultImg,
                title: "Tendon",
                content: "",
                price: "£17.00",
                rating: "3.9",
                reviews: "26",
                url: '/reservation'
            },
            {
                uid: 'db_d04',
                imageSrc: menuItemDefaultImg,
                title: "Tori Katsudon",
                content: "Chicken in bread crumbs with Egg On rice",
                price: "£17.00",
                rating: "3.9",
                reviews: "26",
                url: '/reservation'
            },
            {
                uid: 'db_d05',
                imageSrc: menuItemDefaultImg,
                title: "Chicken Katsu Curry",
                content: "Chicken with Breadcrumbs & Curry Sauce",
                price: "£17.00",
                rating: "3.9",
                reviews: "26",
                url: '/reservation'
            },
            {
                uid: 'db_d06',
                imageSrc: menuItemDefaultImg,
                title: "House Special Fried Rice",
                content: "Chicken, shrimp and Vegetables",
                price: "£14.00",
                rating: "3.9",
                reviews: "26",
                url: '/reservation'
            },
            {
                uid: 'db_d07',
                imageSrc: menuItemDefaultImg,
                title: "Tofu Steak (V)",
                content: "Deep Fried bean curd in Wafu sauce and stir fried vegetable",
                price: "£13.00",
                rating: "3.9",
                reviews: "26",
                url: '/reservation'
            },
            {
                uid: 'db_d08',
                imageSrc: menuItemDefaultImg,
                title: "Plain Boiled Rice",
                content: "",
                price: "£3.00",
                rating: "3.9",
                reviews: "26",
                url: '/reservation'
            },
            {
                uid: 'db_d09',
                imageSrc: menuItemDefaultImg,
                title: "Sushi Rice",
                content: "",
                price: "£4.00",
                rating: "3.9",
                reviews: "26",
                url: '/reservation'
            },
            {
                uid: 'db_d10',
                imageSrc: menuItemDefaultImg,
                title: "Egg Rice",
                content: "",
                price: "£6.00",
                rating: "3.9",
                reviews: "26",
                url: '/reservation'
            },
            {
                uid: 'db_d11',
                imageSrc: menuItemDefaultImg,
                title: "Chicken Teriyaki",
                content: "",
                price: "£16.00",
                rating: "3.9",
                reviews: "26",
                url: '/reservation'
            },
            {
                uid: 'db_d12',
                imageSrc: menuItemDefaultImg,
                title: "Salmon Teriyaki",
                content: "",
                price: "£17.00",
                rating: "3.9",
                reviews: "26",
                url: '/reservation'
            },
            {
                uid: 'db_d13',
                imageSrc: menuItemDefaultImg,
                title: "Salmon Katsu Curry",
                content: "",
                price: "£19.00",
                rating: "3.9",
                reviews: "26",
                url: '/reservation'
            },
            {
                uid: 'db_d14',
                imageSrc: menuItemDefaultImg,
                title: "Ebi Katsu Curry",
                content: "",
                price: "£17.00",
                rating: "3.9",
                reviews: "26",
                url: '/reservation'
            }
        ], 
        'Noodle Soup': [
            // {
            //     uid: 'nvs_ns01',
            //     imageSrc: menuItemDefaultImg,
            //     title: "Tempura Udon",
            //     content: "Prawn and vegetable tempura",
            //     price: "£11",
            //     rating: "3.9",
            //     reviews: "26",
            //     url: '/reservation'
            // },
            // {
            //     uid: 'nvs_ns02',
            //     imageSrc: menuItemDefaultImg,
            //     title: "Yasai Tempura Udon",
            //     content: "Noodle soup with vegetable tempura",
            //     price: "£9",
            //     rating: "3.9",
            //     reviews: "26",
            //     url: '/reservation'
            // },
            // {
            //     uid: 'nvs_ns03',
            //     imageSrc: menuItemDefaultImg,
            //     title: "Plain Noodle Soup Udon",
            //     content: "",
            //     price: "£17.5",
            //     rating: "3.9",
            //     reviews: "26",
            //     url: '/reservation'
            // },
            {
                uid: 'nvs_ns04',
                imageSrc: menuItemDefaultImg,
                title: "Miso Soup",
                content: "",
                price: "£3.00",
                rating: "3.9",
                reviews: "26",
                url: '/reservation'
            },
            {
                uid: 'nvs_ns05',
                imageSrc: menuItemDefaultImg,
                title: "Dobin Mushi Soup",
                content: "",
                price: "£8.50",
                rating: "3.9",
                reviews: "26",
                url: '/reservation'
            },
            // {
            //     uid: 'nvs_ns06',
            //     imageSrc: menuItemDefaultImg,
            //     title: "Vegetable Soup V",
            //     content: "",
            //     price: "£5",
            //     rating: "3.9",
            //     reviews: "26",
            //     url: '/reservation'
            // }
        ],
        // 'Stir Fried Noodles': [
        //     {
        //         uid: 'nvs_sfn01',
        //         imageSrc: menuItemDefaultImg,
        //         title: "Chicken Yaki Udon / Soba",
        //         content: "",
        //         price: "£9",
        //         rating: "3.9",
        //         reviews: "26",
        //         url: '/reservation'
        //     },
        //     {
        //         uid: 'nvs_sfn02',
        //         imageSrc: menuItemDefaultImg,
        //         title: "Seafood Yaki Udon / Soba",
        //         content: "",
        //         price: "£9.5",
        //         rating: "3.9",
        //         reviews: "26",
        //         url: '/reservation'
        //     },
        //     {
        //         uid: 'nvs_sfn03',
        //         imageSrc: menuItemDefaultImg,
        //         title: "Vegetable Yaki Udon / Soba",
        //         content: "",
        //         price: "£8.5",
        //         rating: "3.9",
        //         reviews: "26",
        //         url: '/reservation'
        //     }
        // ],
        'Bento Box': [
            {
                uid: 'db_b01',
                imageSrc: menuItemDefaultImg,
                title: "Chicken Teriyaki Bento",
                content: "Served with Miso soup, Rice and Sashimi",
                price: "£22.00",
                rating: "3.9",
                reviews: "26",
                url: '/reservation'
            },
            {
                uid: 'db_b02',
                imageSrc: menuItemDefaultImg,
                title: "Salmon Teriyaki Bento",
                content: "Served with Miso Rice and Sashimi",
                price: "£23.00",
                rating: "3.9",
                reviews: "26",
                url: '/reservation'
            },
            {
                uid: 'db_b03',
                imageSrc: menuItemDefaultImg,
                title: "Chicken Katsu curry Bento",
                content: "Chicken Katsu with Miso Rice and Sashimi",
                price: "£22.00",
                rating: "3.9",
                reviews: "26",
                url: '/reservation'
            },
            {
                uid: 'db_b04',
                imageSrc: menuItemDefaultImg,
                title: "Tempura Bento",
                content: "Mix tempura with Miso soup, Rice and Sashimi",
                price: "£23.00",
                rating: "3.9",
                reviews: "26",
                url: '/reservation'
            },
            // {
            //     uid: 'db_b05',
            //     imageSrc: menuItemDefaultImg,
            //     title: "Sashimi and sushi Bento",
            //     content: "Mixture sushi and Sashimi with Rice and miso soup",
            //     price: "£20.00",
            //     rating: "3.9",
            //     reviews: "26",
            //     url: '/reservation'
            // },
            {
                uid: 'db_b06',
                imageSrc: menuItemDefaultImg,
                title: "Tuna and salmon Bento",
                content: "Served with Rice and Miso",
                price: "£24.00",
                rating: "3.9",
                reviews: "26",
                url: '/reservation'
            },
            {
                uid: 'db_b07',
                imageSrc: menuItemDefaultImg,
                title: "Vegetable Tempura Bento",
                content: "Served with Rice and Vegetable soup",
                price: "£18.00",
                rating: "3.9",
                reviews: "26",
                url: '/reservation'
            }
        ], 
        'Platters': [
            {
                uid: 'db_p01',
                imageSrc: menuItemDefaultImg,
                title: "Yellow Tail with Jalapeno Platter",
                content: "45-50 slices",
                price: "£110.00",
                rating: "3.9",
                reviews: "26",
                url: '/reservation'
            },
            {
                uid: 'db_p02',
                imageSrc: menuItemDefaultImg,
                title: "Pham Platter",
                content: "64 pieces",
                price: "£110.00",
                rating: "3.9",
                reviews: "26",
                url: '/reservation'
            },
            // {
            //     uid: 'db_p02',
            //     imageSrc: menuItemDefaultImg,
            //     title: "Sushi & Sashimi Platter",
            //     content: "64 pieces",
            //     price: "£85",
            //     rating: "3.9",
            //     reviews: "26",
            //     url: '/reservation'
            // },
            // {
            //     uid: 'db_p03',
            //     imageSrc: menuItemDefaultImg,
            //     title: "Sashimi Platter",
            //     content: "Selection of Sashimi 60 Slices",
            //     price: "£110.00",
            //     rating: "3.9",
            //     reviews: "26",
            //     url: '/reservation'
            // },
            {
                uid: 'db_p04',
                imageSrc: menuItemDefaultImg,
                title: "Platter for 2",
                content: "Selection of Sushi, Sashimi and Nigiri",
                price: "£75.00",
                rating: "3.9",
                reviews: "26",
                url: '/reservation'
            },
            {
                uid: 'db_p05',
                imageSrc: menuItemDefaultImg,
                title: "Deluxe Sushi and Sashimi Platter",
                content: "60 pieces",
                price: "£105.00",
                rating: "3.9",
                reviews: "26",
                url: '/reservation'
            },
            // {
            //     uid: 'db_p05',
            //     imageSrc: menuItemDefaultImg,
            //     title: "Month Platter",
            //     content: "55 pieces",
            //     price: "£85",
            //     rating: "3.9",
            //     reviews: "26",
            //     url: '/reservation'
            // },
            {
                uid: 'db_p06',
                imageSrc: menuItemDefaultImg,
                title: "Hot Stater Platter",
                content: "Mixture of different appetisers",
                price: "£62.00",
                rating: "3.9",
                reviews: "26",
                url: '/reservation'
            },
            {
                uid: 'db_p07',
                imageSrc: menuItemDefaultImg,
                title: "Nigiri Platter",
                content: "37 Pieces of mixed Nigiri",
                price: "£80.00",
                rating: "3.9",
                reviews: "26",
                url: '/reservation'
            },
            {
                uid: 'db_p08',
                imageSrc: menuItemDefaultImg,
                title: "Vegetable Platter",
                content: "",
                price: "£65.00",
                rating: "3.9",
                reviews: "26",
                url: '/reservation'
            },
            {
                uid: 'db_p09',
                imageSrc: menuItemDefaultImg,
                title: "Classic Platter",
                content: "60 pcs in total",
                price: "£100.00",
                rating: "3.9",
                reviews: "26",
                url: '/reservation'
            },
            {
                uid: 'db_p10',
                imageSrc: menuItemDefaultImg,
                title: "Omakase Platter",
                content: "Selection of Sashimi 55-60 Slices",
                price: "£120.00",
                rating: "3.9",
                reviews: "26",
                url: '/reservation'
            },
        ],
    }
};